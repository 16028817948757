import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  {
    path: '/casefile',
    name: 'Download',
    component: () => import('../views/Download.vue'),
    meta: {
      title: '文书下载'
    },
  },
  {
    path: '/seel',
    name: 'SeelAudit',
    component: () => import('../views/SeelAudit.vue'),
    meta: {
      title: '用印授权'
    },
  },
  {
    path: '/seelfile',
    name: 'SeelFile',
    component: () => import('../views/SeelFile.vue'),
    meta: {
      title: '用印文件'
    },
  },
  {
    path: '/',
    name: 'Redirect',
    component: () => import('../views/Redirect.vue'),
    meta: {
      title: '首页'
    },
  },
  {
    path: '/md',
    name: 'Mediate',
    component: () => import('../views/Mediate.vue'),
    meta: {
      title: '调解详情'
    },
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
