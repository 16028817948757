<template>
  <div id="app">
    <router-view :title="$route.meta.title" />
  </div>
</template>

<script>
export default {
  data(){
    return{
      // logo: 'logo.svg'
    }
  },
  // created() {
  //   var link =
  //     document.querySelector("link[rel*='icon']") ||
  //     document.createElement("link");
  //   link.href = this.logo;
  //   document.getElementsByTagName("head")[0].appendChild(link);
  // },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
