import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css'
import {Message} from 'element-ui'
import SlideVerify from 'vue-monoplasty-slide-verify';
// import 'lib-flexible/flexible.js'
// import './utils/px2rem'
// import './utils/flexible'
import vant from 'vant'
import 'vant/lib/index.css'
import {Toast} from 'vant'

Vue.config.productionTip = false
Vue.use(vant)
Vue.use(ElementUI)
Vue.use(SlideVerify)

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

window.eventBus = new Vue()

Vue.prototype.$axios = axios
Vue.prototype.$bus = new Vue()
Vue.prototype.$message = Message

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
